import { useState, FormEventHandler } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Contact = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const sendMessage: FormEventHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://api.bountytrade.com/api/message", {
        name,
        email,
        message,
      });

      setLoading(false);
      toast.success("Message sent");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <header className="header-section bg-color-3">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <Link to="/">
                  <img
                    className="dark"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "220px" }}
                  />
                </Link>
              </div>
              <div className="menu-area">
                <ul
                  className={`${
                    menuOpen ? "menu menu--style1 active" : "menu menu--style1"
                  }`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/plans">Plans</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a
                      href="https://dashboard.bountytrade.com"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>Join Now</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div
                    onClick={toggleMenu}
                    className={`${
                      menuOpen
                        ? "header-bar d-lg-none home1 active"
                        : "header-bar d-lg-none home1"
                    }`}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section
        className="page-header bg--cover"
        style={{ backgroundImage: "url(assets/images/header/1.png)" }}
      >
        <div className="container">
          <div
            className="page-header__content"
            data-aos="fade-right"
            data-aos-duration={1000}
          >
            <h2>Contact Us</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item ">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact Us
                </li>
              </ol>
            </nav>
          </div>
          <div className="page-header__shape">
            <span className="page-header__shape-item page-header__shape-item--1">
              <img src="assets/images/header/2.png" alt="shape-icon" />
            </span>
          </div>
        </div>
      </section>

      <div className="contact padding-top padding-bottom">
        <div className="container">
          <div className="contact__wrapper">
            <div className="row g-5">
              <div className="col-md-5">
                <div
                  className="contact__info"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="contact__social">
                    <h3>
                      <span>get in touch</span> with us
                    </h3>
                  </div>
                  <div className="contact__details">
                    <div
                      className="contact__item"
                      data-aos="fade-right"
                      data-aos-duration={1000}
                    >
                      <div className="contact__item-inner">
                        <div className="contact__item-thumb">
                          <span>
                            <img
                              src="assets/images/contact/1.png"
                              alt="contact-icon"
                              className="dark"
                            />
                          </span>
                        </div>
                        <div className="contact__item-content">
                          <p>+1 (260) 468-8531</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="contact__item"
                      data-aos="fade-right"
                      data-aos-duration={1100}
                    >
                      <div className="contact__item-inner">
                        <div className="contact__item-thumb">
                          <span>
                            <img
                              src="assets/images/contact/2.png"
                              alt="contact-icon"
                              className="dark"
                            />
                          </span>
                        </div>
                        <div className="contact__item-content">
                          <p>contact@bountytrade.com</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="contact__item"
                      data-aos="fade-right"
                      data-aos-duration={1200}
                    >
                      <div className="contact__item-inner">
                        <div className="contact__item-thumb">
                          <span>
                            <img
                              src="assets/images/contact/3.png"
                              alt="contact-icon"
                              className="dark"
                            />
                          </span>
                        </div>
                        <div className="contact__item-content">
                          <p>604 Rose Ave. Bronx</p>
                          <p>NY 10467</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="contact__form">
                  <form
                    data-aos="fade-left"
                    data-aos-duration={1000}
                    onSubmit={sendMessage}
                  >
                    <div className="row g-4">
                      <div className="col-12">
                        <div>
                          <label htmlFor="name" className="form-label">
                            Name
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            placeholder="Full Name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div>
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            className="form-control"
                            type="email"
                            id="email"
                            placeholder="Email here"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div>
                          <label htmlFor="textarea" className="form-label">
                            Message
                          </label>
                          <textarea
                            cols={30}
                            rows={5}
                            className="form-control"
                            id="textarea"
                            placeholder="Enter Your Message"
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {loading ? (
                      <button
                        type="button"
                        disabled
                        className="trk-btn trk-btn--border trk-btn--primary mt-4 d-block"
                      >
                        Sending message...
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="trk-btn trk-btn--border trk-btn--primary mt-4 d-block"
                      >
                        Send message
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact__shape">
          <span className="contact__shape-item contact__shape-item--1">
            <img src="assets/images/contact/4.png" alt="shape-icon" />
          </span>
          <span className="contact__shape-item contact__shape-item--2">
            {" "}
            <span />{" "}
          </span>
        </div>
      </div>
      <Footer />
      <a href="#top" className="scrollToTop scrollToTop--home1">
        <i className="fa-solid fa-arrow-up-from-bracket" />
      </a>

      <ToastContainer autoClose={3000} closeButton={false} />
    </>
  );
};

export default Contact;
