import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const NotFound = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header-section bg-color-3">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <Link to="/">
                  <img
                    className="dark"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "220px" }}
                  />
                </Link>
              </div>
              <div className="menu-area">
                <ul
                  className={`${
                    menuOpen ? "menu menu--style1 active" : "menu menu--style1"
                  }`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/plans">Plans</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a
                      href="https://dashboard.bountytrade.com"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>Join Now</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div
                    onClick={toggleMenu}
                    className={`${
                      menuOpen
                        ? "header-bar d-lg-none home1 active"
                        : "header-bar d-lg-none home1"
                    }`}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="error padding-top padding-bottom sec-bg-color2">
        <div className="container">
          <div className="error__wrapper">
            <div
              className="error__inner"
              data-aos="fade-up"
              data-aos-duration={800}
            >
              <div className="error__thumb text-center">
                <img src="assets/images/others/error.png" alt="404" />
              </div>
              <div className="error__content text-center">
                <h2>
                  <span>oops!</span> page not found
                </h2>
                <p>
                  Oops! It looks like you're lost. The page you were looking for
                  couldn't be found. Don't worry, it happens to the best of us.
                </p>
                <Link
                  to="/"
                  className="trk-btn trk-btn--border trk-btn--primary"
                >
                  Back to home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
