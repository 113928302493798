import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const About = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header-section bg-color-3">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <Link to="/">
                  <img
                    className="dark"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "220px" }}
                  />
                </Link>
              </div>
              <div className="menu-area">
                <ul
                  className={`${
                    menuOpen ? "menu menu--style1 active" : "menu menu--style1"
                  }`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/plans">Plans</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a
                      href="https://dashboard.bountytrade.com"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>Join Now</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div
                    onClick={toggleMenu}
                    className={`${
                      menuOpen
                        ? "header-bar d-lg-none home1 active"
                        : "header-bar d-lg-none home1"
                    }`}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section
        className="page-header bg--cover"
        style={{ backgroundImage: "url(assets/images/header/1.png)" }}
      >
        <div className="container">
          <div
            className="page-header__content"
            data-aos="fade-right"
            data-aos-duration={1000}
          >
            <h2>About Us</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item ">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  About
                </li>
              </ol>
            </nav>
          </div>
          <div className="page-header__shape">
            <span className="page-header__shape-item page-header__shape-item--1">
              <img src="assets/images/header/2.png" alt="shape-icon" />
            </span>
          </div>
        </div>
      </section>

      <div className="story padding-top bg-color-3">
        <div className="container">
          <div className="story__wrapper">
            <div className="story__thumb">
              <div
                className="story__thumb-inner"
                data-aos="fade-up"
                data-aos-duration={800}
              >
                <img src="assets/images/about/4.png" alt="story" />
                <div className="story__thumb-playbtn">
                  <a href="#/" data-fslightbox>
                    <i className="fa-solid fa-circle-play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="story__shape">
          <span className="story__shape-item story__shape-item--1">
            <span />{" "}
          </span>
        </div>
      </div>

      <section className="about about--style1 ">
        <div className="container">
          <div className="about__wrapper">
            <div className="row gx-5  gy-4 gy-sm-0  align-items-center">
              <div className="col-lg-6">
                <div
                  className="about__thumb pe-lg-5"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="about__thumb-inner">
                    <div className="about__thumb-image floating-content">
                      <img
                        className="dark"
                        src="assets/images/about/1.png"
                        alt="about"
                      />
                      <div className="floating-content__top-left">
                        <div className="floating-content__item">
                          <h3>
                            {" "}
                            <span
                              className="purecounter"
                              data-purecounter-start={0}
                              data-purecounter-end={10}
                            >
                              10
                            </span>
                            Years
                          </h3>
                          <p>Consulting Experience</p>
                        </div>
                      </div>
                      <div className="floating-content__bottom-right">
                        <div className="floating-content__item">
                          <h3>
                            {" "}
                            <span
                              className="purecounter"
                              data-purecounter-start={0}
                              data-purecounter-end={25}
                            >
                              25
                            </span>
                            K+
                          </h3>
                          <p>Satisfied Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about__content"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="about__content-inner">
                    <h2>
                      Get to know about <span>our company</span>
                    </h2>
                    <p className="mb-3">
                      Hey there! We're so glad you stopped by. We hope you don't
                      miss out on this opportunity to learn about what we do and
                      the amazing team that makes it all happen! Our company is
                      all about creating innovative solutions and providing
                      top-notch services to our clients. From start to finish,
                      we're dedicated to delivering results that exceed
                      expectations.
                    </p>
                    <p className="mb-0">
                      Bounty Trade was founded in 2011 in New York, USA. We are
                      a leading household name in the financial world here in
                      and fast spreading globally. We haven’t reach the heights
                      we aspire for just yet but currently managing over 25,000
                      accounts comprising of institutional and privately owned
                      accounts in the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta padding-top padding-bottom  bg-color">
        <div className="container">
          <div className="cta__wrapper">
            <div className="cta__newsletter justify-content-center">
              <div
                className="cta__newsletter-inner"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <div className="cta__thumb">
                  <img src="assets/images/cta/3.png" alt="cta-thumb" />
                </div>
                <div className="cta__subscribe">
                  <h2>
                    {" "}
                    <span>Subscribe</span> our news
                  </h2>
                  <p>
                    Hey! Are you tired of missing out on our updates? Subscribe
                    to our news now and stay in the loop!
                  </p>
                  <form
                    className="cta-form cta-form--style2 form-subscribe"
                    action="#"
                  >
                    <div className="cta-form__inner d-sm-flex align-items-center">
                      <input
                        type="email"
                        className="form-control form-control--style2 mb-3 mb-sm-0"
                        placeholder="Email Address"
                      />
                      <button
                        className="trk-btn  trk-btn--large trk-btn--secondary2"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="cta__shape">
              <span className="cta__shape-item cta__shape-item--1">
                <img src="assets/images/cta/2.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--2">
                <img src="assets/images/cta/4.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--3">
                <img src="assets/images/cta/5.png" alt="shape icon" />
              </span>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      <a href="#top" className="scrollToTop scrollToTop--home1">
        <i className="fa-solid fa-arrow-up-from-bracket" />
      </a>
    </>
  );
};

export default About;
