import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Home from "./pages/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";
import Plans from "./pages/Plans";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      var scrollToTop: any = document.querySelector(".scrollToTop");

      if (scrollToTop) {
        if (window.pageYOffset > 300) {
          scrollToTop.style.bottom = "7%";
          scrollToTop.style.opacity = "1";
          scrollToTop.style.transition = "all .5s ease";
        } else {
          scrollToTop.style.bottom = "-30%";
          scrollToTop.style.opacity = "0";
          scrollToTop.style.transition = "all .5s ease";
        }
      }
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/services" element={<Services />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
