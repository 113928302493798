import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header-section bg-color-3">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <Link to="/">
                  <img
                    className="dark"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "220px" }}
                  />
                </Link>
              </div>
              <div className="menu-area">
                <ul
                  className={`${
                    menuOpen ? "menu menu--style1 active" : "menu menu--style1"
                  }`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/plans">Plans</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a
                      href="https://dashboard.bountytrade.com"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>Join Now</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div
                    onClick={toggleMenu}
                    className={`${
                      menuOpen
                        ? "header-bar d-lg-none home1 active"
                        : "header-bar d-lg-none home1"
                    }`}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="banner banner--style1">
        <div className="banner__bg">
          <div className="banner__bg-element">
            <img
              src="assets/images/banner/home1/bg.png"
              alt="section-bg-element"
              className="dark d-none d-lg-block"
            />
            <span className="bg-color d-lg-none" />
          </div>
        </div>
        <div className="container">
          <div className="banner__wrapper">
            <div className="row gy-5 gx-4">
              <div className="col-lg-6 col-md-7">
                <div
                  className="banner__content"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="banner__content-coin">
                    <img
                      src="assets/images/banner/home1/3.png"
                      alt="coin icon"
                    />
                  </div>
                  <h1 className="banner__content-heading">
                    Invest your money with <span>higher returns</span>
                  </h1>
                  <p className="banner__content-moto">
                    Anyone can invest money and increase their earnings with the
                    help of Bounty Trade. Take advantage today!
                  </p>
                  <div className="banner__btn-group btn-group">
                    <a
                      href="https://dashboard.bountytrade.com"
                      className="trk-btn trk-btn--primary trk-btn--arrow"
                    >
                      Get Started
                      <span>
                        <i className="fa-solid fa-arrow-right" />
                      </span>{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-5">
                <div
                  className="banner__thumb"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img
                    src="assets/images/banner/home1/1.png"
                    alt="banner-thumb"
                    className="dark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner__shape">
          <span className="banner__shape-item banner__shape-item--1">
            <img src="assets/images/banner/home1/4.png" alt="shape icon" />
          </span>
        </div>
      </section>

      <div className="partner partner--gradient">
        <div className="container">
          <div className="partner__wrapper">
            <div className="partner__slider swiper">
              <div className="swiper-wrapper">
                <Swiper
                  spaceBetween={24}
                  grabCursor
                  loop
                  slidesPerView={2}
                  breakpoints={{
                    576: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    992: {
                      slidesPerView: 5,
                      spaceBetween: 15,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 25,
                    },
                  }}
                  autoplay={{
                    delay: 1,
                    disableOnInteraction: true,
                  }}
                  speed={2000}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="partner__item">
                        <div className="partner__item-inner">
                          <img
                            src="assets/images/partner/light/1.png"
                            alt="partner logo"
                            className="dark"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="partner__item">
                        <div className="partner__item-inner">
                          <img
                            src="assets/images/partner/light/2.png"
                            alt="partner logo"
                            className="dark"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="partner__item">
                        <div className="partner__item-inner">
                          <img
                            src="assets/images/partner/light/3.png"
                            alt="partner logo"
                            className="dark"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="swiper-slide">
                      <div className="partner__item">
                        <div className="partner__item-inner">
                          <img
                            src="assets/images/partner/light/4.png"
                            alt="partner logo"
                            className="dark"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="partner__item">
                        <div className="partner__item-inner">
                          <img
                            src="assets/images/partner/light/5.png"
                            alt="partner logo"
                            className="dark"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="about about--style1 ">
        <div className="container">
          <div className="about__wrapper">
            <div className="row gx-5  gy-4 gy-sm-0  align-items-center">
              <div className="col-lg-6">
                <div
                  className="about__thumb pe-lg-5"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="about__thumb-inner">
                    <div className="about__thumb-image floating-content">
                      <img
                        className="dark"
                        src="assets/images/about/1.png"
                        alt="about"
                      />
                      <div className="floating-content__top-left">
                        <div className="floating-content__item">
                          <h3>
                            {" "}
                            <span
                              className="purecounter"
                              data-purecounter-start={0}
                              data-purecounter-end={10}
                            >
                              10
                            </span>
                            Years
                          </h3>
                          <p>Consulting Experience</p>
                        </div>
                      </div>
                      <div className="floating-content__bottom-right">
                        <div className="floating-content__item">
                          <h3>
                            {" "}
                            <span
                              className="purecounter"
                              data-purecounter-start={0}
                              data-purecounter-end={25}
                            >
                              25
                            </span>
                            K+
                          </h3>
                          <p>Satisfied Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about__content"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="about__content-inner">
                    <h2>
                      Get to know about <span>our company</span>
                    </h2>
                    <p className="mb-3">
                      Hey there! We're so glad you stopped by. We hope you don't
                      miss out on this opportunity to learn about what we do and
                      the amazing team that makes it all happen! Our company is
                      all about creating innovative solutions and providing
                      top-notch services to our clients. From start to finish,
                      we're dedicated to delivering results that exceed
                      expectations.
                    </p>
                    <p className="mb-0">
                      Bounty Trade was founded in 2011 in New York, USA. We are
                      a leading household name in the financial world here in
                      and fast spreading globally. We haven’t reach the heights
                      we aspire for just yet but currently managing over 25,000
                      accounts comprising of institutional and privately owned
                      accounts in the world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feature feature--style1 padding-bottom padding-top bg-color">
        <div className="container">
          <div className="feature__wrapper">
            <div className="row g-5 align-items-center justify-content-between">
              <div className="col-md-6 col-lg-5">
                <div
                  className="feature__content"
                  data-aos="fade-right"
                  data-aos-duration={800}
                >
                  <div className="feature__content-inner">
                    <div className="section-header">
                      <h2 className="mb-15 mt-minus-5">
                        {" "}
                        <span>benefits </span>We offer
                      </h2>
                      <p className="mb-0">
                        Unlock the full potential of our product with our
                        amazing features and top-notch services
                      </p>
                    </div>
                    <div className="feature__nav">
                      <div
                        className="nav nav--feature flex-column nav-pills"
                        id="feat-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <div
                          className="nav-link active"
                          id="feat-pills-one-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-one"
                          role="tab"
                          aria-controls="feat-pills-one"
                          aria-selected="true"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>Lending money for investment purposes</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link"
                          id="feat-pills-two-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-two"
                          role="tab"
                          aria-controls="feat-pills-two"
                          aria-selected="false"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  more Security and control over money from the
                                  rest
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link"
                          id="feat-pills-three-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-three"
                          role="tab"
                          aria-controls="feat-pills-three"
                          aria-selected="false"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  Mobile payment is more flexible and easy for
                                  all investors
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="nav-link"
                          id="feat-pills-four-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#feat-pills-four"
                          role="tab"
                          aria-controls="feat-pills-four"
                          aria-selected="false"
                        >
                          <div className="feature__item">
                            <div className="feature__item-inner">
                              <div className="feature__item-content">
                                <h6>
                                  all transactions kept free for pro traders
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div
                  className="feature__thumb pt-5 pt-md-0"
                  data-aos="fade-left"
                  data-aos-duration={800}
                >
                  <div className="feature__thumb-inner">
                    <div className="tab-content" id="feat-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="feat-pills-one"
                        role="tabpanel"
                        aria-labelledby="feat-pills-one-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="assets/images/feature/1.png"
                            alt="Feature"
                          />
                          <div
                            className="floating-content__top-right floating-content__top-right--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style2 text-center">
                              <img
                                src="assets/images/feature/5.png"
                                alt="rating"
                              />
                              <p className="style2">Interest Rate For Loan</p>
                            </div>
                          </div>
                          <div
                            className="floating-content__bottom-left floating-content__bottom-left--style2"
                            data-aos="fade-left"
                            data-aos-duration={1000}
                          >
                            <div className="floating-content__item floating-content__item--style3  d-flex align-items-center">
                              <h3 className="style2">
                                {" "}
                                <span
                                  className="purecounter"
                                  data-purecounter-start={0}
                                  data-purecounter-end={10}
                                >
                                  10M
                                </span>
                                M
                              </h3>
                              <p className="ms-3 style2">Available for loan</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="feat-pills-two"
                        role="tabpanel"
                        aria-labelledby="feat-pills-two-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="assets/images/feature/2.png"
                            alt="Feature"
                          />
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="feat-pills-three"
                        role="tabpanel"
                        aria-labelledby="feat-pills-three-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="assets/images/feature/1.png"
                            alt="Feature"
                          />
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="feat-pills-four"
                        role="tabpanel"
                        aria-labelledby="feat-pills-four-tab"
                        tabIndex={0}
                      >
                        <div className="feature__image floating-content">
                          <img
                            src="assets/images/feature/2.png"
                            alt="Feature"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature__shape">
          <span className="feature__shape-item feature__shape-item--1">
            <img src="assets/images/feature/shape/1.png" alt="shape-icon" />
          </span>
          <span className="feature__shape-item feature__shape-item--2">
            {" "}
            <span />{" "}
          </span>
        </div>
      </section>

      <section className="service padding-top padding-bottom">
        <div className="section-header section-header--max50">
          <h2 className="mb-15 mt-minus-5">
            <span>services </span>We offer
          </h2>
          <p>
            We offer the best financial services around - from investments to
            consultation, management, and more!
          </p>
        </div>
        <div className="container">
          <div className="service__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/1.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Strategy Consulting
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        A social assistant that's flexible can accommodate your
                        schedule and needs, making life easier.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/2.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          {" "}
                          Financial Advisory
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        Modules transform smart trading by automating processes
                        and improving decision-making.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/3.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Management
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        There, it's me, your friendly neighborhood reporter's
                        news analyst processes and improving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/4.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Supply Optimization{" "}
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey, have you checked out that new cryptocurrency
                        platform? It's pretty cool and easy to use!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/5.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          HR consulting
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        Hey guys, just a quick update on exchange orders. There
                        have been some changes currency!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/6.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Marketing consulting
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey! Just wanted to let you know that the price
                        notification module processes is now live!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service__shape">
          <span className="service__shape-item service__shape-item--1">
            <img src="assets/images/icon/1.png" alt="shape-icon" />
          </span>
        </div>
      </section>

      <section className="pricing padding-top padding-bottom">
        <div className="section-header section-header--max50">
          <h2 className="mb-15 mt-minus-5">
            Our <span>pricing </span>plans
          </h2>
        </div>
        <div className="container">
          <div className="pricing__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Golden</h6>
                        <h3 className="mb-25">$5,000 - $50,000</h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            9% interest daily
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            4 weeks duration
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="https://dashboard.bountytrade.com"
                          className="trk-btn trk-btn--outline"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item "
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Sterling</h6>
                        <h3 className="mb-25">$51,000 - $100,000</h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            9% daily interest
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            4 weeks duration
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="https://dashboard.bountytrade.com"
                          className="trk-btn trk-btn--outline"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">premium</h6>
                        <h3 className="mb-25">$110,000 - $1,000,000</h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            15% daily interest
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            3 months duration
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="https://dashboard.bountytrade.com"
                          className="trk-btn trk-btn--outline"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div
                  className="pricing__item"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <div className="pricing__item-inner">
                    <div className="pricing__item-content">
                      {/* pricing top part */}
                      <div className="pricing__item-top">
                        <h6 className="mb-15">Platinum</h6>
                        <h3 className="mb-25">$2,000,000 - $10,000,000</h3>
                      </div>
                      {/* pricing middle part */}
                      <div className="pricing__item-middle">
                        <ul className="pricing__list">
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            15% daily interest
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            3 months duration
                          </li>
                          <li className="pricing__list-item">
                            <span>
                              <img
                                src="assets/images/icon/check.svg"
                                alt="check"
                                className="dark"
                              />
                            </span>
                            24/7 technical support
                          </li>
                        </ul>
                      </div>
                      {/* pricing bottom part */}
                      <div className="pricing__item-bottom">
                        <a
                          href="https://dashboard.bountytrade.com"
                          className="trk-btn trk-btn--outline"
                        >
                          Choose Plan
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing__shape">
          <span className="pricing__shape-item pricing__shape-item--1">
            {" "}
            <span />{" "}
          </span>
          <span className="pricing__shape-item pricing__shape-item--2">
            {" "}
            <img src="assets/images/icon/1.png" alt="shape-icon" />
          </span>
        </div>
      </section>

      <section className="blog padding-top padding-bottom">
        <div className="container">
          <div className="section-header d-md-flex align-items-center justify-content-between">
            <div className="section-header__content">
              <h2 className="mb-15">
                <span>articles</span> for pro traders
              </h2>
              <p className="mb-0">
                Hey there pro traders, check out these articles with tips to
                take your trading game to the next level!
              </p>
            </div>
            <div className="section-header__action">
              <div className="swiper-nav swiper-nav--style1">
                <button className="swiper-nav__btn blog__slider-prev">
                  <i className="fa-solid fa-angle-left" />
                </button>
                <button className="swiper-nav__btn blog__slider-next active">
                  <i className="fa-solid fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="blog__wrapper"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="blog__slider swiper">
              <div className="swiper-wrapper">
                <Swiper
                  spaceBetween={24}
                  grabCursor
                  loop
                  slidesPerView={1}
                  breakpoints={{
                    576: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                  }}
                  autoplay
                  speed={500}
                  navigation={{
                    nextEl: ".blog__slider-next",
                    prevEl: ".blog__slider-prev",
                  }}
                  modules={[Autoplay, Navigation]}
                >
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="blog__item blog__item--style2">
                        <div className="blog__item-inner">
                          <div className="blog__thumb">
                            <img
                              src="assets/images/blog/1.png"
                              alt="blog Images"
                            />
                          </div>
                          <div className="blog__content">
                            <div className="blog__meta">
                              <span className="blog__meta-tag blog__meta-tag--style1">
                                Forex trading
                              </span>
                            </div>
                            <h5>
                              {" "}
                              <a href="#/">Swing Trading Definition</a>{" "}
                            </h5>
                            <p className="mb-15">
                              Our platform is not only about trading—it's also a
                              hub for knowledge and learning. We provide
                              resources.
                            </p>
                            <div className="blog__writer">
                              <div className="blog__writer-thumb">
                                <img
                                  src="assets/images/blog/author/1.png"
                                  alt="writer"
                                />
                              </div>
                              <div className="blog__writer-designation">
                                <h6 className="mb-0">Vasha Gueye</h6>
                                <span>20/6/2023</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="blog__item blog__item--style2">
                        <div className="blog__item-inner">
                          <div className="blog__thumb">
                            <img
                              src="assets/images/blog/2.png"
                              alt="blog Images"
                            />
                          </div>
                          <div className="blog__content">
                            <div className="blog__meta">
                              <span className="blog__meta-tag blog__meta-tag--style1">
                                Trading market
                              </span>
                            </div>
                            <h5>
                              {" "}
                              <a href="#/">hedge funds work?</a>{" "}
                            </h5>
                            <p className="mb-15">
                              To cater to your individual trading preferences,
                              we offer a variety of order types, including
                              market.
                            </p>
                            <div className="blog__writer">
                              <div className="blog__writer-thumb">
                                <img
                                  src="assets/images/blog/author/2.png"
                                  alt="writer"
                                />
                              </div>
                              <div className="blog__writer-designation">
                                <h6 className="mb-0">Abhivibha Kanmani</h6>
                                <span>30/5/2023</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="blog__item blog__item--style2">
                        <div className="blog__item-inner">
                          <div className="blog__thumb">
                            <img
                              src="assets/images/blog/3.png"
                              alt="blog Images"
                            />
                          </div>
                          <div className="blog__content">
                            <div className="blog__meta">
                              <span className="blog__meta-tag blog__meta-tag--style1">
                                Investment
                              </span>
                            </div>
                            <h5>
                              {" "}
                              <a href="#/">Options Trading business?</a>{" "}
                            </h5>
                            <p className="mb-15">
                              Security is our top priority, and we employ robust
                              measures to ensure the safety of your funds.
                            </p>
                            <div className="blog__writer">
                              <div className="blog__writer-thumb">
                                <img
                                  src="assets/images/blog/author/3.png"
                                  alt="writer"
                                />
                              </div>
                              <div className="blog__writer-designation">
                                <h6 className="mb-0">Hulya Aydin</h6>
                                <span>12/07/2023</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
          {/* <div className="text-center">
            <a
              href="#/"
              className="trk-btn trk-btn--border trk-btn--primary mt-40"
            >
              View more{" "}
            </a>
          </div> */}
        </div>
        <div className="blog__shape">
          <span className="blog__shape-item blog__shape-item--1">
            {" "}
            <span />{" "}
          </span>
        </div>
      </section>
      <section className="testimonial padding-top padding-bottom-style2 bg-color">
        <div className="container">
          <div className="section-header d-md-flex align-items-center justify-content-between">
            <div className="section-header__content">
              <h2 className="mb-15">
                connect with <span>our Clients </span>
              </h2>
              <p className="mb-0">
                We love connecting with our clients to hear about their
                experiences and how we can improve.
              </p>
            </div>
            <div className="section-header__action">
              <div className="swiper-nav">
                <button className="swiper-nav__btn testimonial__slider-prev">
                  <i className="fa-solid fa-angle-left" />
                </button>
                <button className="swiper-nav__btn testimonial__slider-next active">
                  <i className="fa-solid fa-angle-right" />
                </button>
              </div>
            </div>
          </div>
          <div
            className="testimonial__wrapper"
            data-aos="fade-up"
            data-aos-duration={1000}
          >
            <div className="testimonial__slider swiper">
              <div className="swiper-wrapper">
                <Swiper
                  spaceBetween={24}
                  grabCursor
                  loop
                  slidesPerView={1}
                  breakpoints={{
                    576: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 2,
                    },
                    1200: {
                      slidesPerView: 2,
                      spaceBetween: 25,
                    },
                  }}
                  autoplay
                  speed={500}
                  navigation={{
                    nextEl: ".testimonial__slider-next",
                    prevEl: ".testimonial__slider-prev",
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="testimonial__item testimonial__item--style1">
                        <div className="testimonial__item-inner">
                          <div className="testimonial__item-content">
                            <p className="mb-0">
                              I was skeptical about investing in cryptocurrency
                              until I stumbled upon this site. It has been a
                              game-changer for me! The platform is
                              user-friendly, and the support team is always
                              there to help. I've seen significant returns on my
                              investments, and I couldn't be happier. This site
                              has truly made crypto investing accessible and
                              profitable.
                            </p>
                            <div className="testimonial__footer">
                              <div className="testimonial__author">
                                <div className="testimonial__author-thumb">
                                  <img
                                    src="assets/images/testimonial/1.png"
                                    alt="author"
                                  />
                                </div>
                                <div className="testimonial__author-designation">
                                  <h6>Mobarok Hossain</h6>
                                  <span>Trade Master</span>
                                </div>
                              </div>
                              <div className="testimonial__quote">
                                <span>
                                  <i className="fa-solid fa-quote-right" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="testimonial__item testimonial__item--style1">
                        <div className="testimonial__item-inner">
                          <div className="testimonial__item-content">
                            <p className="mb-0">
                              I've been trading cryptocurrencies for years, and
                              I've used various platforms. However, this crypto
                              investment site stands out for its reliability and
                              transparency. The security measures in place gave
                              me peace of mind, and the real-time data and
                              analysis tools are invaluable. It's become my
                              go-to platform for crypto investments, and I
                              highly recommend it to anyone looking to get into
                              the crypto market.
                            </p>
                            <div className="testimonial__footer">
                              <div className="testimonial__author">
                                <div className="testimonial__author-thumb">
                                  <img
                                    src="assets/images/testimonial/2.png"
                                    alt="author"
                                  />
                                </div>
                                <div className="testimonial__author-designation">
                                  <h6>Guy Hawkins</h6>
                                  <span>Trade Boss</span>
                                </div>
                              </div>
                              <div className="testimonial__quote">
                                <span>
                                  <i className="fa-solid fa-quote-right" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="swiper-slide">
                      <div className="testimonial__item testimonial__item--style1">
                        <div className="testimonial__item-inner">
                          <div className="testimonial__item-content">
                            <p className="mb-0">
                              I'm relatively new to the world of crypto, but
                              this site has been a fantastic place to start. The
                              educational resources and guides helped me
                              understand the basics, and the intuitive interface
                              made trading easy even for a beginner like me.
                              What's more, I've already seen impressive gains on
                              my investments in a short period. This site has
                              turned my curiosity about crypto into a profitable
                              venture. Thanks!
                            </p>
                            <div className="testimonial__footer">
                              <div className="testimonial__author">
                                <div className="testimonial__author-thumb">
                                  <img
                                    src="assets/images/testimonial/6.png"
                                    alt="author"
                                  />
                                </div>
                                <div className="testimonial__author-designation">
                                  <h6>Belal Hossain</h6>
                                  <span>Trade Newbie</span>
                                </div>
                              </div>
                              <div className="testimonial__quote">
                                <span>
                                  <i className="fa-solid fa-quote-right" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq padding-top padding-bottom of-hidden">
        <div className="section-header section-header--max65">
          <h2 className="mb-15 mt-minus-5">
            <span>Frequently</span> Asked questions
          </h2>
          <p>
            Hey there! Got questions? We've got answers. Check out our FAQ
            section for all the deets. Still not satisfied? Hit us up.
          </p>
        </div>
        <div className="container">
          <div className="faq__wrapper">
            <div className="row g-5 align-items-center justify-content-between">
              <div className="col-lg-6">
                <div
                  className="accordion accordion--style1"
                  id="faqAccordion1"
                  data-aos="fade-right"
                  data-aos-duration={1000}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="accordion__item ">
                        <div className="accordion__header" id="faq1">
                          <button
                            className="accordion__button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody1"
                            aria-expanded="false"
                            aria-controls="faqBody1"
                          >
                            <span className="accordion__button-content">
                              What is cryptocurrency investing?
                            </span>
                            <span className="accordion__button-plusicon" />
                          </button>
                        </div>
                        <div
                          id="faqBody1"
                          className="accordion-collapse collapse show"
                          aria-labelledby="faq1"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body">
                            <p className="mb-15">
                              Cryptocurrency investing involves buying and
                              holding digital assets with the expectation that
                              they will increase in value over time. It's a form
                              of long-term or short-term investment in
                              cryptocurrencies like Bitcoin, Ethereum, and
                              others.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item ">
                        <div className="accordion__header" id="faq2">
                          <button
                            className="accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody2"
                            aria-expanded="true"
                            aria-controls="faqBody2"
                          >
                            <span className="accordion__button-content">
                              How do I get started with crypto investing?
                            </span>
                            <span className="accordion__button-plusicon" />
                          </button>
                        </div>
                        <div
                          id="faqBody2"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq2"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body">
                            <p className="mb-15">
                              To get started, sign up on our platform, complete
                              the KYC process, and deposit funds. Then, browse
                              the available cryptocurrencies, select the ones
                              you want to invest in, and make your first
                              investment. Our user-friendly interface makes it
                              easy for beginners to start investing.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item ">
                        <div className="accordion__header" id="faq3">
                          <button
                            className="accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody3"
                            aria-expanded="false"
                            aria-controls="faqBody3"
                          >
                            <span className="accordion__button-content">
                              Is my investment safe on this platform?
                            </span>
                            <span className="accordion__button-plusicon" />
                          </button>
                        </div>
                        <div
                          id="faqBody3"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq3"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body">
                            <p className="mb-15">
                              Yes, we prioritize security. We employ robust
                              encryption and multi-factor authentication to
                              safeguard your account. The majority of user funds
                              are stored in cold wallets, which are offline and
                              highly secure. Additionally, we adhere to industry
                              best practices to protect your investments.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item ">
                        <div className="accordion__header" id="faq4">
                          <button
                            className="accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody4"
                            aria-expanded="false"
                            aria-controls="faqBody4"
                          >
                            <span className="accordion__button-content">
                              Can I withdraw my funds at any time?
                            </span>
                            <span className="accordion__button-plusicon" />
                          </button>
                        </div>
                        <div
                          id="faqBody4"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq4"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body">
                            <p className="mb-15">
                              Absolutely! You have full control over your funds.
                              You can withdraw your cryptocurrency holdings at
                              any time, subject to withdrawal fees and any
                              relevant lock-up periods associated with certain
                              investment products.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item ">
                        <div className="accordion__header" id="faq5">
                          <button
                            className="accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody5"
                            aria-expanded="false"
                            aria-controls="faqBody5"
                          >
                            <span className="accordion__button-content">
                              What cryptocurrencies can I invest in on this
                              platform?
                            </span>
                            <span className="accordion__button-plusicon" />
                          </button>
                        </div>
                        <div
                          id="faqBody5"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq5"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body">
                            <p className="mb-15">
                              We offer a wide range of cryptocurrencies for
                              investment, including Bitcoin, Ethereum and many
                              more. Our platform regularly updates the available
                              options to provide you with a diverse investment
                              portfolio.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="accordion__item border-0">
                        <div className="accordion__header" id="faq6">
                          <button
                            className="accordion__button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#faqBody6"
                            aria-expanded="false"
                            aria-controls="faqBody6"
                          >
                            <span className="accordion__button-content">
                              How can I track the performance of my investments?
                            </span>
                            <span className="accordion__button-plusicon" />
                          </button>
                        </div>
                        <div
                          id="faqBody6"
                          className="accordion-collapse collapse"
                          aria-labelledby="faq6"
                          data-bs-parent="#faqAccordion1"
                        >
                          <div className="accordion__body">
                            <p className="mb-15">
                              Monitoring your investments is easy. Once you've
                              made an investment, you can view your portfolio's
                              performance in real-time on your dashboard. We
                              also provide data and analysis tools to help you
                              make informed decisions.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="faq__thumb faq__thumb--style1"
                  data-aos="fade-left"
                  data-aos-duration={1000}
                >
                  <img
                    className="dark"
                    src="assets/images/others/1.png"
                    alt="faq-thumb"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq__shape faq__shape--style1">
          <span className="faq__shape-item faq__shape-item--1">
            <img src="assets/images/others/2.png" alt="shpae-icon" />
          </span>
        </div>
      </section>

      <section className="cta padding-top padding-bottom  bg-color">
        <div className="container">
          <div className="cta__wrapper">
            <div className="cta__newsletter justify-content-center">
              <div
                className="cta__newsletter-inner"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <div className="cta__thumb">
                  <img src="assets/images/cta/3.png" alt="cta-thumb" />
                </div>
                <div className="cta__subscribe">
                  <h2>
                    {" "}
                    <span>Subscribe</span> our news
                  </h2>
                  <p>
                    Hey! Are you tired of missing out on our updates? Subscribe
                    to our news now and stay in the loop!
                  </p>
                  <form
                    className="cta-form cta-form--style2 form-subscribe"
                    action="#/"
                  >
                    <div className="cta-form__inner d-sm-flex align-items-center">
                      <input
                        type="email"
                        className="form-control form-control--style2 mb-3 mb-sm-0"
                        placeholder="Email Address"
                      />
                      <button
                        className="trk-btn  trk-btn--large trk-btn--secondary2"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="cta__shape">
              <span className="cta__shape-item cta__shape-item--1">
                <img src="assets/images/cta/2.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--2">
                <img src="assets/images/cta/4.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--3">
                <img src="assets/images/cta/5.png" alt="shape icon" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <a href="#top" className="scrollToTop scrollToTop--home1">
        <i className="fa-solid fa-arrow-up-from-bracket" />
      </a>
    </>
  );
};

export default Home;
