import { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const Services = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header-section bg-color-3">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper">
              <div className="logo">
                <Link to="/">
                  <img
                    className="dark"
                    src="assets/images/logo/logo.png"
                    alt="logo"
                    style={{ width: "220px" }}
                  />
                </Link>
              </div>
              <div className="menu-area">
                <ul
                  className={`${
                    menuOpen ? "menu menu--style1 active" : "menu menu--style1"
                  }`}
                >
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/plans">Plans</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
              <div className="header-action">
                <div className="menu-area">
                  <div className="header-btn">
                    <a
                      href="https://dashboard.bountytrade.com"
                      className="trk-btn trk-btn--border trk-btn--primary"
                    >
                      <span>Join Now</span>
                    </a>
                  </div>
                  {/* toggle icons */}
                  <div
                    onClick={toggleMenu}
                    className={`${
                      menuOpen
                        ? "header-bar d-lg-none home1 active"
                        : "header-bar d-lg-none home1"
                    }`}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section
        className="page-header bg--cover"
        style={{ backgroundImage: "url(assets/images/header/1.png)" }}
      >
        <div className="container">
          <div
            className="page-header__content"
            data-aos="fade-right"
            data-aos-duration={1000}
          >
            <h2>Services</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item ">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Services
                </li>
              </ol>
            </nav>
          </div>
          <div className="page-header__shape">
            <span className="page-header__shape-item page-header__shape-item--1">
              <img src="assets/images/header/2.png" alt="shape-icon" />
            </span>
          </div>
        </div>
      </section>

      <section className="service padding-top padding-bottom">
        <div className="section-header section-header--max50">
          <h2 className="mb-15 mt-minus-5">
            <span>services </span>We offer
          </h2>
          <p>
            We offer the best financial services around - from investments to
            consultation, management, and more!
          </p>
        </div>
        <div className="container">
          <div className="service__wrapper">
            <div className="row g-4 align-items-center">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/1.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Strategy Consulting
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        A social assistant that's flexible can accommodate your
                        schedule and needs, making life easier.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/2.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          {" "}
                          Financial Advisory
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        Modules transform smart trading by automating processes
                        and improving decision-making.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/3.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Management
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        There, it's me, your friendly neighborhood reporter's
                        news analyst processes and improving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={800}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/4.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Supply Optimization{" "}
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey, have you checked out that new cryptocurrency
                        platform? It's pretty cool and easy to use!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1000}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/5.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          HR consulting
                        </a>{" "}
                      </h5>
                      <p className="mb-0">
                        Hey guys, just a quick update on exchange orders. There
                        have been some changes currency!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div
                  className="service__item"
                  data-aos="fade-up"
                  data-aos-duration={1200}
                >
                  <div className="service__item-inner text-center">
                    <div className="service__thumb mb-30">
                      <div className="service__thumb-inner">
                        <img
                          className="dark"
                          src="assets/images/service/6.png"
                          alt="service-icon"
                        />
                      </div>
                    </div>
                    <div className="service__content">
                      <h5 className="mb-15">
                        {" "}
                        <a className="stretched-link" href="#/">
                          Marketing consulting
                        </a>
                      </h5>
                      <p className="mb-0">
                        Hey! Just wanted to let you know that the price
                        notification module processes is now live!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service__shape">
          <span className="service__shape-item service__shape-item--1">
            <img src="assets/images/icon/1.png" alt="shape-icon" />
          </span>
        </div>
      </section>

      <section className="cta padding-top padding-bottom  bg-color">
        <div className="container">
          <div className="cta__wrapper">
            <div className="cta__newsletter justify-content-center">
              <div
                className="cta__newsletter-inner"
                data-aos="fade-up"
                data-aos-duration={1000}
              >
                <div className="cta__thumb">
                  <img src="assets/images/cta/3.png" alt="cta-thumb" />
                </div>
                <div className="cta__subscribe">
                  <h2>
                    {" "}
                    <span>Subscribe</span> our news
                  </h2>
                  <p>
                    Hey! Are you tired of missing out on our updates? Subscribe
                    to our news now and stay in the loop!
                  </p>
                  <form
                    className="cta-form cta-form--style2 form-subscribe"
                    action="#/"
                  >
                    <div className="cta-form__inner d-sm-flex align-items-center">
                      <input
                        type="email"
                        className="form-control form-control--style2 mb-3 mb-sm-0"
                        placeholder="Email Address"
                      />
                      <button
                        className="trk-btn  trk-btn--large trk-btn--secondary2"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="cta__shape">
              <span className="cta__shape-item cta__shape-item--1">
                <img src="assets/images/cta/2.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--2">
                <img src="assets/images/cta/4.png" alt="shape icon" />
              </span>
              <span className="cta__shape-item cta__shape-item--3">
                <img src="assets/images/cta/5.png" alt="shape icon" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <a href="#top" className="scrollToTop scrollToTop--home1">
        <i className="fa-solid fa-arrow-up-from-bracket" />
      </a>
    </>
  );
};

export default Services;
