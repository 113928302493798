import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer brand-1">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__top footer__top--style1">
            <div className="row gy-5 gx-4">
              <div className="col-md-6">
                <div className="footer__about">
                  <Link to="/" className="footer__about-logo">
                    <img
                      src="assets/images/logo/logo-dark.png"
                      alt="Logo"
                      style={{ width: "220px" }}
                    />
                  </Link>
                  <p className="footer__about-moto ">
                    Welcome to our trading site! We offer the best, most
                    affordable products and services around. Invest now and
                    start enjoying profits!
                  </p>
                </div>
              </div>
              <div className="col-md-2 col-sm-4 col-6">
                <div className="footer__links">
                  <div className="footer__links-tittle">
                    <h6>Quick links</h6>
                  </div>
                  <div className="footer__links-content">
                    <ul className="footer__linklist">
                      <li className="footer__linklist-item">
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="footer__linklist-item">
                        <Link to="/plans">Plans</Link>
                      </li>
                      <li className="footer__linklist-item">
                        <Link to="/services">Services</Link>{" "}
                      </li>
                      <li className="footer__linklist-item">
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2 col-sm-4 col-6">
                <div className="footer__links">
                  <div className="footer__links-tittle">
                    <h6>Support</h6>
                  </div>
                  <div className="footer__links-content">
                    <ul className="footer__linklist">
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Terms &amp; Conditions</a>
                      </li>
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Privacy Policy</a>
                      </li>
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">FAQs</a>
                      </li>
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Support Center</a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-md-2 col-sm-4">
                <div className="footer__links">
                  <div className="footer__links-tittle">
                    <h6>Company</h6>
                  </div>
                  <div className="footer__links-content">
                    <ul className="footer__linklist">
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Careers</a>
                      </li>
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Updates</a>
                      </li>
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Job</a>{" "}
                      </li>
                      <li className="footer__linklist-item">
                        {" "}
                        <a href="#/">Announce</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__end">
              <div className="footer__end-copyright">
                <p className=" mb-0">
                  © {new Date().getFullYear()} All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__shape">
        <span className="footer__shape-item footer__shape-item--1">
          <img src="assets/images/footer/1.png" alt="shape icon" />
        </span>
        <span className="footer__shape-item footer__shape-item--2">
          {" "}
          <span />{" "}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
